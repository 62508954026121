/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(window).load(function() {
            $('.loader').fadeOut(1000);
        });

        var windowHeight = $(window).height();
        var windowWidth = $(window).width();

        setTimeout(function(){
          $('.header .logo').addClass('in-corner');
          $('.header .burger-menu').addClass('in-corner');
        }, 800);

        if(windowWidth >= 768){
            $(window).scroll(function(){
                var scroll = $(window).scrollTop();

                if(windowWidth >= 768) {
                    if(scroll > 200){
                        $('.header').addClass('fixed');
                    } else {
                        $('.header').removeClass('fixed');
                    }
                } else {
                    $('.header').removeClass('fixed');
                }

            });
        }
    
        $(window).resize(function() {
            windowHeight = $(window).height();
            windowWidth = $(window).width();

            // FLIP
            // il faut préalablement définir la taille de l'image
            var imgVidheight = $('.video-img').width() / 1.775;
            // console.log(imgVidheight);
            $('.video-img, .back, .video-card').height(imgVidheight);

            if(windowWidth >= 768) {
                $('.fit-screen').height(windowHeight);
                $('.banner').parallax("50%", 0.5);
                $('.banner-parallax').parallax("50%", 0.2);
            } else {
              $('.fit-screen').css('height','460px');
               $('.banner').parallax("50%", 0.0);
               $('.banner-parallax').parallax("50%", 0.0);
            }

            if(windowWidth > 1024) {
                $('.fit-screen').height(windowHeight);
            }
        });


        $(window).trigger('resize');
        $(window).trigger('scroll');

        $('.parallax-window').parallax();

        // CLICK SUR LE BURGER MENU
        $('.burger-menu').click(function(){
          var $mainNav = $('.main-nav');
          var $icone = $('.burger-menu img');

          $mainNav.toggleClass('open');

          if($mainNav.hasClass('open')){
              $icone.attr('src', sitePath + '/assets/images/ui/icone-close-thin.svg');
              $icone.addClass('icone-close');

          } else {
              $icone.attr('src', sitePath + '/assets/images/ui/icone-burger.svg');
              $icone.removeClass('icone-close');
          }
        });

        // Flip video
          // il faut préalablement définir la taille de l'image
          var imgVidheight = $('.video-img').width() / 1.775;
          // console.log(imgVidheight);
          $('.video-img, .back, .video-card').height(imgVidheight);


          $(".video-card").flip({
            axis: 'x',
            trigger: 'manual'
          });

          $('.btn-more').click(function(e){
            e.preventDefault();

            //on supprime les url de chaque video
            var videos = $('.video-preview').find('iframe');
            videos.attr("src","");

            // on fait reapparaitre l'image
            var videosIMG = $('.video-preview').find('.video-img');
            videosIMG.fadeIn(600);

            //flip
            var frontFlipCard = $(this).parents('.video-preview').find('.video-card');
            
            if( $(this).hasClass('back-open') ){
              frontFlipCard.flip(false);
              $(this).children('img').removeAttr("style");
              // $(this).children('img').removeClass('close-more');
              $(this).removeClass('back-open');
            } else {
              // on tourne la croix et ajoute la classe back-open
              frontFlipCard.flip(true);
              $(this).children('img').css('transform','rotate(135deg)');
              // $(this).children('img').addClass('close-more');
              $(this).addClass('back-open');
            }
          });

          // fade de l'image
          $('.video-btn').click(function(e){
            e.preventDefault();

            var videoIMG = $(this).parents('.video-preview').find('.video-img');
            videoIMG.fadeOut(1000);

            var iframe = $(this).parents('.video-preview').find('iframe');
            var video = $(this).data('video');
            var videourl = "https://www.youtube.com/embed/"+video+"?rel=0&autoplay=1&amp;controls=0&amp;showinfo=0";
            iframe.attr("src", videourl);

          });

      }, 
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_page_nouvelles': {
      init: function() {
          var paged=2;      
          var currentFilter = []
          currentFilter["categorie"]="";        
          currentFilter["date"]="";        

        
          function get_more_news(){
              var cat = $("[data-type='categorie'] .select-name span").html();
              var date = $("[data-type='date'] .select-name span").html();
              var search = $("#formSearch").find("[type='text']").val();
              
              var formData = {action:"get_more_news",  paged:paged, categorie:currentFilter["categorie"], date:currentFilter["date"], search:search};           
              $.ajax({
                url:ajaxurl,
                data:formData,
                method:"POST",

              }).done(function(data){
                $("#container-news").append(data);
                if(data !== ""){
                  paged++;
                }
              });
          }
        
          function get_news(t, v){
            $("#txtNoResult").hide();
            $("#container-news").html("loading");
            
            var formData = {action:"get_news",  type:t, value:v};           
            $.ajax({
              url:ajaxurl,
              data:formData,
              method:"POST",
              
            }).done(function(data){
              $("#container-news").html(data);
              if(data === ""){
                $("#txtNoResult").show();
              }
            });
          }
          
          function resetFilter(){
              //reset filter
              $(".select-name span").each(function(){
                $(this).html($(this).data("label"));
                currentFilter["categorie"]="";        
                currentFilter["date"]="";                  
              });             
          }
          
          //SEARCH
          $("#formSearch").submit(function(e){
            e.preventDefault();
            var value = $(this).find("[type='text']").val();
            get_news("recherche", value);
            
            resetFilter();
            
            //reset pagination
            paged = 2;            
          });
          
          //LOAD MORE
          document.getElementById("btnLoadMore").addEventListener("click", get_more_news);
        

          var $searchForm  = $('.search-form');
        
          //ANIMATION SELECT
          $('.select-name').click(function(){
              $(this).parent().find('.select-list').toggleClass('open');
              $(this).parent().find('.icone-arrow').toggleClass('pointing-up');
          });
          
          //Select Item
          $('.select-list li').click(function(){
     
              resetFilter();
            
              var text = $(this).html();        
              var value = $(this).data("value");
              var type = $(this).closest("[data-js='pn-select']").data("type");
              currentFilter[type] = value;
              get_news(type, value);
              
              
              $(this).closest("[data-js='pn-select']").find('.select-name span').html(text);
              $(this).closest("[data-js='pn-select']").find('.select-list').toggleClass('open');
              $(this).closest("[data-js='pn-select']").find('.icone-arrow').toggleClass('pointing-up');
              
              //reset pagination
              paged = 2;
          });          

          //ANIMATION FORMULAIRE
          $('.search-icon').click(function(){
              $searchForm.toggleClass('open');

              if($searchForm.hasClass('open')){
                  $('.search-icon img').attr('src', sitePath + '/assets/images/ui/icone-close.svg');
              } else {
                  $('.search-icon img').attr('src', sitePath + '/assets/images/ui/icone-search.svg');
              }
          });

          $(window).resize(function() {
             var windowWidth = $(window).width();
              if(windowWidth < 768) {
                $searchForm.addClass('open');
              } 
          });

          $(window).trigger('resize');
      }
    },
    'page_template_page_processus_transport': {
      init: function() {
          //SET HEIGHT DES BOITES
          var targetHeight = $('.info-img img').height();
          var windowWidth = $(window).width();

          //$('.info-text, .info-title, .info-img').height(targetHeight);

          // ANIMATION DES BOITE PROCESSUS
          $.fn.visible = function(partial) {
              
              var $t            = $(this),
                  $w            = $(window),
                  viewTop       = $w.scrollTop(),
                  viewBottom    = viewTop + $w.height(),
                  _top          = $t.offset().top,
                  _bottom       = _top + $t.height(),
                  compareTop    = partial === true ? _bottom : _top,
                  compareBottom = partial === true ? _top : _bottom;
            
            return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

          };

       $(function() {
          function imageLoaded() {
             // function to invoke for loaded image
             
             $(window).resize(function() {
                windowWidth = $(window).width();
                targetHeight = $('.info-img img').height();

                if(windowWidth >= 768) {
                   $('.info-text, .info-title, .info-img').height(targetHeight);
                   var win = $(window);
                   var allMods = $(".module");
                   var allMods2 = $(".module2");
                   var el = $(el);


                   allMods.each(function(i, el) {
                     el = $(el);
                     if (el.visible(true)) {
                       el.addClass("already-visible"); 
                     } 
                   });

                   allMods2.each(function(i, el) {
                     el = $(el);
                     if (el.visible(true)) {
                       el.addClass("already-visible"); 
                     } 
                   });

                   win.scroll(function(event) {
                     
                     allMods.each(function(i, el) {
                       el = $(el);
                       if (el.visible(true)) {
                         el.addClass("come-in"); 
                       } 
                     });
                   });

                   win.scroll(function(event) {
                     
                     allMods2.each(function(i, el) {
                       el = $(el);
                       if (el.visible(true)) {
                         el.addClass("come-in-right"); 
                       } 
                     });
                     
                   });
                } else {
                  $('.info-text, .info-title, .info-img').css('height', 'auto');
                }
            });

           $(window).trigger('resize');

          }
          $('.info-img img').each(function() {
              if( this.complete ) {
                  imageLoaded.call( this );
              } else {
                  $(this).one('load', imageLoaded);
              }
          });
      });

      }
    },
    'page_template_page_contact': {
      init: function() {
        
        var $form = $("#formMail"),
            $formWrap = $('.js-form-wrap'),
            $spinner = $('.js-form-spinner');        
        
        //Envoi mail par ajax
        $form.submit(function(e){
          e.preventDefault();
          $form.find(".txt-notification").hide();

          var error = 0;
          $form.find("[data-required]").each(function(){
            $(this).removeClass("data-required");
            if($(this).val() === ""){
              $("#txtFormError").slideDown(300);
              $(this).addClass("data-required");
              error=1;
            }
          });

          //Si pas erreur,  on envoi
          if(error === 0){
            $spinner.show();

            var sData = $(this).serialize();
            $.ajax({
              method: "POST",
              url: ajaxurl,
             // dataType : "json",
              data:  sData 
            })
            .done(function( data ) {
              if(data === "1") {
                $formWrap.html($("#txtFormSent").show());

                $spinner.hide();
              }
            });
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
